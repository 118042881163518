export default {
	common: {
		noMore: '더 이상 없습니다',
		noEmpty: '반드시 업로드',
		copySuccess: '복사 성공',
		copyFail: '복사 실패',
		search: '검색',
		remark: '비고',
		commit: '제출',
		status: '상태',
		revoke: '취소',
		cancel: '취소',
		confirm: '확인',
		getbtn: '수령',
		selectTime: '시간 선택',
		selectDate: '날짜 선택',
		uploadImage: '사진 업로드',
		uploadVideo: '동영상 업로드',
		activity: '이벤트',
		problem: '문제',
		money: '금액',
		reply: '답변',
		other: '기타',
		send: '발송',
		copy: '복사',
		view: '보기',
		next: '다음',
		feedback: '피드백 할래',
		enter: '입력하세요',
		loading: '로딩 중...',
		searchkey: '검색어를 입력해 주세요',
		back: '돌아가기',
		download: '다운로드',
		detail: '상세',
		mUnit: '₩',
		thousand: '천',
		tenThousand: '만',
		tenMillion: '천만',
		billion: '억',
		hundredBillion: '천억',
		trillions: '만억',
		quadrillions: '천만억',
	},
	upload: {
		label: '업로드',
		loading: '업로드 중...',
		success: '업로드 성공',
		fail: '업로드 실패',
		sizeTips: '파일 크기는 {size} M 초과할 수 없습니다',
	},
	menu: {
		home: '메인',
		news: '이슈 정보',
		personal: '개인정보',
		coupon: '10만 할인 쿠폰',
		pay: '결제',
		clauseUser: '프라이버시 정책',
		clauseService: '고객 서비스 센터',
		about: '우리에 대해- 회사소개',
		contact: '저희한테 연락하세요 - 고객지원',
		refund: '환불정책',
		pcdownload: 'PC 설치 설명'
	},
	login: {
		name: '계정',
		pass: '비밀번호',
		nameTips: '계정을 입력하세요',
		passTips: '비밀번호를 입력하세요',
		submitBtn: '확인',
		accountTitle: '로그인',
		thirdTitle: '타사 로그인',
		googleLogin: 'google로그인'
	},
	home: {
		tips:'GM 운영진 카톡 아이디：GM20238',
		slogans:'모든 게임 50% 할인되는 정보 app',
		news: '이슈 정보',
		recommend: '최신 혜택 정보',
		recommendGame: '추천 게임',
		recommendDesc: '공식 추천 게임',
		hotGame: '핫 게임',
		hotDesc: '가장 핫한 게임',
		gameDownload: '다운로드',
		pcDownload: 'PC 다운로드',
		downloadUrl: '다운로드 링크',
		activity: '이벤트',
		introduction: '공략',
		offlineActivity: '오프라인 이벤트',
		openService: '서버 오픈 공지',
		releaseTime: '출시 시간',
		gameNotice: '게임 공지',
		prebookGame: '신규 게임 예약',
		prebookDesc: '최신 공개, 신규게임 첫 출시',
		guidesTitle: '추가 가이드',
		btnText: '10만원 할인쿠폰 활성화 성공',
		guidesText:'순서대로 진행 시 10만원 할인쿠폰 획득 가능',
		newstotal: '현재 공략 {num} 개 있습니다',
		gamePlay: '게임 바로 시작',
		couponRules: '사용 설명',
		serviceTime: '시간',
		serviceGame: '게임',
		serviceList: '목록'
	},
	pay: {
		title: '결제 센터',
		orderMoney: '주문 금액',
		allCoupon: '전액 할인쿠폰',
		halfCoupon: '반값 할인쿠폰',
		total: '합계',
		discount: '할인',
		paybtn: '결제',
		authConfirm: '실명인증 가기',
		authCancel: '본인인증 완료',
		payJumpTitle: '결제 알림',
		payJumpText: '결제하시겠습니까？',
		payRecharge: '즉시 충전',
		recharge: '충전',
		rechargeMoney: '충전 금액',
		rechargeTitle: '충전 금액 선택',
		rechargePlaceholder: '충전 금액 입력하세요',
		tipsInputRecharge: 'error .',
		goodMoney: '상품 가격',
		discountCoupon: '할인쿠폰',
		gmCoin: 'GM 코인',
		payType: '결제수단',
		discountMoney: '할인',
		maxDiscountTips: '스페셜 할인 적용 완료',
		discountTitle: '할인쿠폰을 선택하세요',
		noUseDiscount: '할인쿠폰 사용하지 않음',
		expTime: '유효 기한',
		percentDiscount: '100% 할인 쿠폰',
		halfPercentDiscount: '50% 할인 쿠폰',
		onlyForthisGame: '본게임에서만 사용 가능',
		fullReduction: '{num} 원 이상 결제 시 사용',
		gmCoinTitle: 'GM 코인',
		payTypeTitle: '결제 수단 선택',
		gmCoinTips: 'GM 코인은 GM 시리즈 패키지 교환에 사용 가능',
		allGMCoin: '보유 GM 코인',
		useGMCoin: 'GM 코인 소모',
		remainGMCoin: '남은 GM 코인',
		selectPayTitle: '결제 수단 선택',
		gmCoinPlaceholder: '소모할 GM 코인을 100단위로 입력해주십시오.',
		freeGetGM: '무료 획득',
		needPayTips: '유저님이 입력하신 GM코인과 할인쿠폰을 중첩 사용한 후, 결제해야할 금액이 100원 미만되어 다시 입력하시나 선택해주세요~',
		detail: '상세',
		payTypeTips: '할인쿠폰과 GM코인을 동시에 사용할 수 없습니다',
		payMaxLimit: '최고 {num}할인',
		payLimitTips: '남은 금액 없어 GM코인으로 할인할 수 없습니다~'
	},
	personal: {
		platId: '플랫폼 ID',
		platCoin: 'GM 코인',
		myPlatCoin: '현재 GM 코인',
		loginBtn: '로그인',
		loginOut: '로그아웃',
		googleEmail: 'Google 메일',
		tipsLogin: '우선 로그인 해주십시오',
		commonUser:'일반 사용자',
		vipDetail:'특권 보기'
	},
	footer: {
		aboutus: '회사 소개',
		contact: '고객센터 연락처',
		service: '개인정보취급방침',
		refund: '환불정책',
		userService: '서비스이용약관',
		telLabel: '전화번호',
		telValue: '01088727088',
		licenseNumLabel: '사업자등록번호',
		licenseNumValue: '387-81-02165',
		companyLabel: '상호명',
		companyValue: '귀위안 주식회사',
		legalPersonLabel: '대표자명',
		legalPersonValue: 'YU CHAOSHUYI',
		emailLabel: '이메일',
		emailValue: "hongdenglonghk0330{'@'}gmail.com",
		bankLabel: '은행계좌',
		bankValue: 'KEB HANA BANK 189-910052-14504',
		businessNumLabel: '통신판매업신고',
		businessNumValue: '제2023-서울강남-03332호',
		addressLabel: '사업장주소',
		addressValue: '서울특별시 강남구 영동대로 602,6층 에이치41(삼성동,미켈라 107)',
		support: '© 2023 Powered by GM',
		useManual: '이용안내'
	}
}